import { Box } from '@chakra-ui/react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';

import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';

import { Layout } from '$components/Layout';
import { PinValidation } from '$src/components/PinValidation';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useLogin, useLoginChallenge } from '$src/hooks/mutations';
import { captureMixpanelAnalytics, capturePosthogAnalytics } from '$src/utils';

export const getServerSideProps: GetServerSideProps = async ({ res, locale = 'fr' }) => {
  res.setHeader('Cache-Control', 'public, s-maxage=180, stale-while-revalidate=360');
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};

const Login: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = () => {
  useEffect(() => {
    captureMixpanelAnalytics('login_session_start_REDESIGN');
    capturePosthogAnalytics('login_session_start_REDESIGN');
  }, []);

  const { onChallenge, status } = useLoginChallenge();
  const { mutateAsync: onLogin } = useLogin();
  const { track } = useTrackAmplitude();

  useEffect(() => {
    track('OTP asked');
  }, []);

  return (
    <Layout>
      <Box backgroundColor="white" display="flex" flexDirection="column" height="100%">
        <PinValidation onChallenge={onChallenge} status={status} resendPin={onLogin} />
      </Box>
    </Layout>
  );
};

export default Login;
