import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Button, JustDrawer, PinInput, useJustDrawer } from '@getjust/leaf';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useMemo } from 'react';

import { useRouter } from '$hooks/client';
import { useEmailAtom, useFormMessageAtom, useROEmailAtom } from '$hooks/state';
import { REFS_MAP } from '$src/constants';

interface PinValidationProps {
  onChallenge: (code: string) => Promise<unknown>;
  status: 'error' | 'idle' | 'loading' | 'success';
  resendPin: (email: string) => Promise<unknown>;
}

export const PinValidation = ({ onChallenge, status, resendPin }: PinValidationProps) => {
  const { onEmailChange } = useEmailAtom();
  const { removeCurrentUser } = useFormMessageAtom();
  const { t, i18n } = useTranslation('common');
  const email = useROEmailAtom();
  const router = useRouter();
  const { onOpen } = useJustDrawer();

  const onResendPinClick = useCallback(() => {
    resendPin(email!);
  }, [email, resendPin]);

  const onBackClick = useCallback(() => {
    onEmailChange(); // we empty email state to avoid infinite reopening of pinValidation
    removeCurrentUser(); // we remove message currentUser to avoid infinite reopening of pinValidation
    router.push('/unauthenticated');
  }, [onEmailChange, removeCurrentUser, router]);

  const emailValue = useMemo(() => {
    return email;
  }, []);
  return (
    <JustDrawer
      containerRef={REFS_MAP.get('portal')}
      title={t('otp.verify_email_title')}
      isOpen
      onClose={onBackClick}
      onOpen={onOpen}
      openIcon={'/images/eyeCart.svg'}
    >
      <VStack spacing="2" justifyContent="flex-start" mt="3">
        <VStack alignItems="flex-start">
          <Text className="pin-validation">{t('otp.welcomeBack')}</Text>
          <VStack spacing="1" mt="12" mb="2.75rem">
            <Text
              color="#1C1C1C"
              fontSize="lg"
              fontStyle="normal"
              fontWeight="medium"
              lineHeight="6"
              letterSpacing="tighter"
              alignSelf="stretch"
            >
              {t('otp.notice_email')}
            </Text>
            (
            <HStack justifyContent="center" alignItems="center" mt="2">
              <Image
                src="/images/emailIcon.svg"
                alt="email icon"
                borderRadius="full"
                bg="#E0E0E0"
                alignContent="center"
                alignItems="center"
                p="1"
              />
              <Text color="#585858" fontSize="md" fontStyle="normal" fontWeight="350" lineHeight="6">
                {emailValue}
              </Text>
            </HStack>
            )
          </VStack>
          <PinInput isDisabled={status === 'loading'} passwordSize={6} onComplete={onChallenge} />
        </VStack>
        <Button
          cursor="pointer"
          onClick={onResendPinClick}
          variant="secondary"
          label={t('otp.resend_email')}
          mt="6"
        />
        <HStack alignItems="center" pb="2" mt="8" spacing={1}>
          <Text color="#8F8F8F" textAlign="center" fontSize="xs">
            {t('secured_payment')}
          </Text>
          <a
            href={
              i18n.language === 'fr'
                ? 'https://www.getjust.eu/fr/consommateurs'
                : 'https://www.getjust.eu/shoppers'
            }
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
            rel="noreferrer"
          >
            <Image alt="JUST" src="/images/smallJust.svg" />
          </a>
        </HStack>
      </VStack>
    </JustDrawer>
  );
};
